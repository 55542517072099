<template>
  <div id="app">
    <v-app id="inspire">
      <span class="backroundimage"></span>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md7 lg5>

            <v-card shaped class="elevation-12 rounded-corner px-8" color="#f1eff2">
              <div class="logo">
                <v-img src="../assets/nageela_logo_med.png"></v-img>
              </div>
              <div v-if="forgotpassword !== true">
                <v-card-text>
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-text-field
                        label="Login"
                        name="login"
                        prepend-icon="mdi-email"
                        type="text"
                        :rules="[v => !!v || 'E-mail is required', v => (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v)) || 'E-mail must be valid']"
                        v-model="email"
                    ></v-text-field>
                    <v-text-field
                        v-on:keyup.enter="login"
                        label="Password"
                        name="password"
                        prepend-icon="mdi-lock"
                        v-model="password"
                        :type="showPassword ? 'text' : 'password'"
                        :rules="[v => !!v || 'Password is required']"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="showPassword = !showPassword"
                    ></v-text-field>
                  </v-form>
                  <div class="danger-alert" v-html="error"/>
                  <v-card-text></v-card-text>
                </v-card-text>
                <v-card-actions class="justify-center">
                  <v-btn
                      rounded
                      large
                      @click="register"
                      text
                      color="primary"
                  >
                    Register
                    <v-icon>mdi-account-plus-outline</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                      rounded
                      large
                      text
                      :loading="loading1"
                      @click="login"
                      :disabled="loading1 ||!valid"
                      color="primary"
                  >
                    Login
                    <v-icon>mdi-lock-open-variant-outline</v-icon>
                  </v-btn>
                </v-card-actions>
                <p @click="forgotPassword" class="text-center">Forgot Password?</p>
                <br/>
              </div>
              <div v-if="forgotpassword == true">
                <div class="text-center" v-if="resetsent != true">
                  <h2>Forgot Your Password?</h2>
                  <v-icon color="#FFA500" size="70">mdi-lock-question</v-icon>
                  <br/>
                  <br/>
                  <h3>Enter your email address and we will send you instructions to reset you password</h3>
                  <v-card-text>
                    <v-form ref="form2" v-model="valid2" lazy-validation>
                      <v-text-field
                          label="Email"
                          name="login"
                          prepend-icon="mdi-email"
                          type="text"
                          :rules="[v => !!v || 'E-mail is required', v => /.+@.+/.test(v) || 'E-mail must be valid']"
                          v-model="resetemail"
                          v-on:keyup.enter="resetPassword"

                      ></v-text-field>
                    </v-form>

                    <v-card-text></v-card-text>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn rounded large @click="backToLoginPage" color="primary">Return To Login</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        rounded
                        large
                        @click="resetPassword"
                        :disabled="loading1 ||!valid2"
                        color="primary"
                    >Reset Password
                    </v-btn>
                  </v-card-actions>
                </div>
                <div class="text-center" v-if="resetsent == true">
                  <h2>Password Reset Sent</h2>
                  <br/>
                  <h3>Please check your email for instructions how reset your password</h3>
                  <br/>
                  <br/>
                  <br/>
                  <v-btn rounded large @click="backToLoginPage" color="primary">Login</v-btn>
                  <br/>
                  <br/>
                </div>
              </div>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-app>
  </div>
</template>
<script>
import userServices from "@/services/Users";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      showPassword: false,
      password: null,
      email: null,
      valid: true,
      valid2: true,
      error: null,
      loading1: false,
      loading2: false,
      forgotpassword: false,
      resetemail: null,
      resetsent: false
    };
  },
  async mounted() {
    if (this.$store.state.status >= 1) this.$router.push({name: "Home"});
  },
  methods: {
    async login() {
      if (this.$refs.form.validate())
        try {
          this.loading1 = true;
          const response = await userServices.login({
            email: this.email,
            password: this.password
          });
          await this.$store.dispatch("authentication/login", response.data);
          this.$router.push({name: "Home"});
          this.loading1 = false;
        } catch (err) {
          this.error = err.response.data.error;
          this.loading1 = false;
        }
    },
    register() {
      this.$router.push({name: "Register"});
    },
    forgotPassword() {
      this.forgotpassword = true;
      this.error = null;
    },
    backToLoginPage() {
      this.resetsent = false;
      this.forgotpassword = false;
    },
    async resetPassword() {
      if (this.$refs.form2.validate())
        try {
          this.loading1 = true;
          const response = await userServices.forgotPassword({
            Email: this.resetemail
          });
          this.loading1 = false;
          this.resetsent = true;
        } catch (err) {
          this.error = err.response.data.error;
          this.loading1 = false;
        }
    }
  }
};
</script>
<style scoped>
* {
  font-family: 'Poppins', sans-serif;
}
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 160px;
  margin: 0 auto;
}

.backroundimage {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url("../assets/login_background.jpg") no-repeat center center;
  background-size: cover;
  filter: brightness(50%);
}

.rounded-corner {
  border-radius: 20px;
}
</style>